import './styles/Bar.css'
import { useNavigate } from 'react-router-dom';

function Bar()
{
    const navigate = useNavigate();

    return (
        <div className="bar_main">
            <div className="nav_btn" onClick={() => navigate("/")}>wafis</div>
            <div className="nav_btn" onClick={() => navigate("/about")}>čo to je</div>
        </div>
    );
}

export default Bar;