import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import About from './About';

import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Result from './Result';

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />
	},
	{
		path: "/about",
		element: <About />
	},
	{
		path: "/result",
		element: <Result />
	}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 	<React.StrictMode>
    	<RouterProvider router={router}/>
  	</React.StrictMode>
);

