import { useLocation } from 'react-router-dom';
import Bar from './Bar';
import './styles/result.css'
import { useEffect, useState } from 'react';
import { useAnimate, motion, animate } from 'framer-motion'
import { useNavigate } from 'react-router-dom';


function Result() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [res, setRes] = useState('');
    const [res_time, setRes_time] = useState(0.0);
    const [loader, setLoader] = useState(true);

    const [current_url, setCurrent_url] = useState('');
    const [urlin, setUrlin] = useState("");
	const [urlin_err, setUrlin_state] = useState(true);

    async function call_detect_api(url)
    {
        console.log(url)
        fetch(`https://wafisapi-4xuvaypqza-lm.a.run.app/urlin?url=${url}` ,{
            method: 'GET',
            
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
        .then((response) => response.json())
        .then(async (data) => {
            await fess_anim();
            setLoader(false);
            setRes(data.res);
            setRes_time(data.time);
            console.log(data.res);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    function edit_url(raw)
    {
        let url = raw
        if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0)
            url = url.replace(/^https?:\/\//, '')
        if (url.split('.').length < 3)
            url = 'www.' + url
        let splitted = url.split('.')
        if (splitted[2].split('/').length > 1)
            url = splitted[0] + "." + splitted[1] + "." + splitted[2].split('/')[0]

        return url;
    }

    
    
    
    function isValidUrl(str) {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
            );
            return pattern.test(str);
        }
        function contro()
    {	
        if (urlin === '' || !urlin.replace(/\s/g, '').length)
        {
            setUrlin_state(false);
        }
        else if (!isValidUrl(urlin))
        {
            setUrlin_state(false);
        }
        else
        {
            setCurrent_url(edit_url(urlin));
            setUrlin_state(true);
            setLoader(true);
            call_detect_api(edit_url(urlin))
        }
        
        setUrlin('');
    }
    
    function res_center()
    {
        let svg_type = <></>
        let head_text = ''
        let sub_text = <></>
        let img_src = ''
        switch(res)
        {
            case 'yes':
                svg_type = (     
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
                    <motion.path
                    d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z"
                    fill="white"
                    
                    />
                </svg>
                )
                head_text = "WAF Detekovaný";
                sub_text = (
                    <motion.div className='res_text'
                        initial={{opacity: 0}}
                        ref={res_scope3}
                    >
                    na stránke <a href={'http://' + current_url} target='_blank'>{current_url}</a> bol 
                    úspešne detekovaný web-aplikačný firewall.
                    Stránka je ochránena voči najčastejším typom útokov. 
                    Použivateľské dáta na tejto stránke
                    sú ochranené pred útočnikmi. 
                    Pre viac info, <b onClick={() => navigate('/about')}>kliknite sem</b>.
                </motion.div>
                )
                img_src = 'imgs/found.svg';
                break;
                case 'not':
                    case 'no':
                svg_type = (     
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
                    <path 
                        d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
                        fill='white'
                        />
                </svg>
                )
                if (res === 'no')
                {
                    head_text = "WAF Nedetekovaný"
                    sub_text = (
                        <motion.div className='res_text' initial={{opacity: 0}} ref={res_scope3}>
                        Na stránke <a href={'http://' + current_url} target='_blank'>{current_url}</a> sa
                        bohužial nepodarilo detekovať web-aplikačný firewall, stránka je bezpečná na prehliadanie, avšak
                        odporúčame nezadávať do stránky žiadne citlivé údaje, ako sú heslá, mail adresy,
                        mená a osobné súbory. Uživateľské dáta na tejto stránke nie sú ochránené voči 
                        potencionálnym útočnikom. Pre viac info, <b onClick={() => navigate("/about")}>kliknite sem</b>
                    </motion.div>
                    )
                    img_src = 'imgs/warning.svg';
                    
                }
                else
                {
                    head_text = 'Nepodarilo sa pripojiť'
                    sub_text = (
                        <motion.div className='res_text' ref={res_scope3} initial={{opacity: 0}}>
                        Stránku <a href={'http://' + current_url} target='_blank'>{current_url}</a> &nbsp;
                        sa bohužiaľ nepodarilo nájsť, skúste si prekontrolovať zadanú url adresu, či je všetko v poriadku.
                        Uistite sa či stránka naozaj existuje kliknutím na link v prvom riadku, alebo manuálne
                        zadajte adresu do vyhľadávača, napr.:  <a href={'https://google.com'} target='_blank'>www.google.com</a>
                    </motion.div>
                    )
                    img_src = 'imgs/notexisting.svg';
                }
                break;
            default:
                break;
        }
            
            
        if (loader)
        {
            return (
                <motion.div className='res_center_loading' ref={fireplace_scope} initial={{opacity: 1, scale: 1}}>
                    <img src='imgs/fireplace.gif'></img>
                    Loading...
                </motion.div>
            )
        }
        else
        {
            
            return (
                <div className='res_center_loaded'>
                    <div className='brick'>
                        <motion.img
                            initial={{opacity: 0}}
                            src={img_src}
                            ref={res_scope}
                        ></motion.img>
                    </div>
                    <div className='result'>
                        <div className='res_head'>
                            {/* the square with tick */}
                            <motion.div 
                                initial={{opacity: 0, scale: 0}}
                                ref={box_scope}
                                className='check_box'
                                style={res === 'yes' ? {backgroundColor: 'rgba(71, 160, 37, 0.6)'} : {backgroundColor: 'rgba(214, 73, 51, 0.8)'}}
                                >
                               {svg_type}
                            </motion.div>

                            <motion.h1
                                initial={{opacity: 0}}
                                ref={res_scope2}
                            >{head_text}</motion.h1>
                        </div>
                       
                        {sub_text}
                        
                    </div>
                </div>
            )
        }
        
        
    }

    const [fireplace_scope, fireplace_anim] = useAnimate();
    const [box_scope, box_anim] = useAnimate();
    const [res_scope, res_anim] = useAnimate();
    const [res_scope2, res_anim2] = useAnimate();
    const [res_scope3, res_anim3] = useAnimate();

    async function fess_anim()
    {
        await fireplace_anim(fireplace_scope.current, {opacity: 0, scale: 0}, {duration: 0.4, decelerate: true})
        
    }
    async function anim_result()
    {
        await box_anim(box_scope.current, {opacity: 1, scale: 1}, {duration: 0.5, delay: 0.5});
        // await box_anim(box_scope.current, {x: '0%', y: '0%'}, {duration: 1, delay: 0.3});
        await res_anim(res_scope.current, {opacity: 1}, {duration: 0.3, delay: 0.4})
        await res_anim2(res_scope2.current, {opacity: 1}, {duration: 0.3})
        await res_anim3(res_scope3.current, {opacity: 1}, {duration: 0.3})
    }


    useEffect(() => {
        setCurrent_url(state.res);
        setUrlin(state.res)
        call_detect_api(edit_url(state.res))
        
        
    }, [])
    useEffect(() => {
        if (!loader)
            anim_result();   
    })
    
    return (
        <div className="res_idk">
			<Bar />
            <div className='res_main'>

                <div className='res_form'>
                    <div>Je Tam <b>WAF</b> ?</div>
                    <input 
                        value={urlin}
                        placeholder="www.google.com"
						style={urlin_err ? {border: "1px solid rgba(0, 0, 0, 0.3)"} : {border: "2px solid red"}}
						onChange={(e) => {
                            setUrlin(e.target.value)
							setUrlin_state(true);
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter')
								contro()
						}}
                        />
                    <button onClick={contro}></button>
                </div>
                {res_center()}

            </div>
    	</div>
  	);
}

export default Result;
