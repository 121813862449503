import Bar from './Bar';
import './styles/App.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAnimate, motion } from 'framer-motion'

function App() {
	const navigate = useNavigate();

	const [urlin, setUrlin] = useState("");
	const [urlin_err, setUrlin_state] = useState(true);
	const [urltitle, setUrltitle] = useState("sem vložte url adresu");

	const [scope_title, animate] = useAnimate();
	const [scope_form, animate_form] = useAnimate();
	const [scope_btn, animate_btn] = useAnimate();
	const [scope_about, animate_abt] = useAnimate();


	function isValidUrl(str) {
		const pattern = new RegExp(
		  '^([a-zA-Z]+:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', // fragment locator
		  'i'
		);
		return pattern.test(str);
	}

	function contro()
	{	
		if (urlin === '' || !urlin.replace(/\s/g, '').length)
		{
			setUrlin_state(false);
			setUrltitle("textové pole nemôže ostať prázdne, vložte url adresu");
		}
		else if (!isValidUrl(urlin))
		{
			setUrltitle("musíte vložiť validnú url adresu, napr. www.google.com");
			setUrlin_state(false);
		}
		else
		{
			setUrlin_state(true);
			navigate(
				"/result", {state: {res: urlin}}
			)
		}

		setUrlin('');
	}

	async function intro_anim()
	{
		await animate(scope_title.current, { opacity: 1,  scale: 1}, {duration: 0.5})
		await animate(scope_title.current, 
			{ y: 0}, {type: "spring", stiffness: 50}
		)
		await animate_form(scope_form.current, { opacity: 1 })
		await animate_btn(scope_btn.current, 
			{opacity: 1, scale: 1}, 
			{type: "tween", stiffness: 40}
		)
		await animate_abt(scope_about.current, { opacity: 1}, {duration: 0.5, delay: 0.5})
	}

	useEffect(() => { 
		
		intro_anim() }, []);

  	return (
    	<div className="App">
			<Bar />
			<div className="app_main">
				<motion.div

					initial={{y: 200, opacity: 0, scale: 0}}
					ref={scope_title}
					className="title">
					Je Tam <div onClick={() => navigate("/about")}>&nbsp;WAF&nbsp;</div>?
				</motion.div>
				<motion.div 
					initial={{opacity: 0}}
					ref={scope_about}
					className="about_link" 
					onClick={() => navigate("/about")}
				>čo to je</motion.div>
				<motion.div 
					className="form" 
					initial={{opacity: 0}}
					ref={scope_form}
				>
					<div>{urltitle}</div>
					<input 
						value={urlin}
						className="url_in" 
						placeholder="www.google.com"
						style={urlin_err ? {border: "1px solid rgba(0, 0, 0, 0.3)"} : {border: "2px solid red"}}
						onChange={(e) => {
							setUrlin(e.target.value)
							setUrlin_state(true);
							setUrltitle("sem vložte url adresu");
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter')
								contro()
						}}
					/>
				</motion.div>
				<motion.button
					ref={scope_btn}
					initial={{opacity: 0, scale: 0}}
					className="submit"
					onClick={contro}
				>Skontrolovať</motion.button>
			</div>
    	</div>
  	);
}

export default App;
