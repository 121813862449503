import { useNavigate } from 'react-router-dom';
import Bar from './Bar';
import "./styles/about.css"

function About() {
  	const navig = useNavigate();
	
	return (
    	<div className='main_about'>
			<Bar />
			<div className='main2_about'>
				<h1>Na čo to teda je ?</h1>
				<div className='under_head'>
					<p className='boc' onClick={() => navig('/')}>WAF</p><p className='boc2'>Web-aplikačný firewall</p>
				</div>
				<p className='definition_nerd'>
					Zabezpečovací systém, ktorý používa set pravidiel na filtrovanie HTTP 
					trafiky medzi webovou aplikáciou a internetom.
				</p>
				<p className='definition_nerd'>
					Je celkom dost chaotická definícia, tak si to skúsme vysvetliť inak...
				</p>

			</div>
			<div className='main3_about'>
				<div className='definition_nonerd'>
					<img src='/imgs/cars.gif' />
					<p>WAF by sme si mohli predstaviť ako colnicu. 
						Každé auto prechádzajúce cez hranicu je skontrolované či 
						neprenáša nejaké nelegálne veci. Veľmi podobne funguje 
						WAF, tak ako colnica, dáta prechádzajúce medzi stránkou a 
						internetom sú skontrolované WAF-om. vďaka ktorému môžme
						dáta sledovať, knotrolovať a popripáde aj filtrovať.
					</p>
				</div>
				<h1 className='why'>Prečo by sme to potrebovali robiť ?</h1>
				<p className='thatswhy'>
					Jedným z dôvodov, a tým najdôležitejším, je ochrana údajov, a to nie len tých
					ktoré tvoria samotnú stránku, ale aj <b>vaše</b> dáta. Heslá, e-maily, kreditné karty
					a veškerú vašu aktivitu na danej stránke. Ak stránka na ktorej sa nachádzate nie je vybavená
					systémom WAF, udáva do nebezpečenstva nie len dáta stránky a danej spoločnosti, ktorá stránku
					vlastní alebo spravuje, ale aj <b>vaše</b> dáta!
				</p>
				<p className='thatswhy'>
					WAF si môžme predstaviť ešte niečo ako aj obranu voči útokom.
					Predsa len ide o niečo ako nástroj ktorý sleduje dáta, dôvod prečo
					ich sledujeme je aby sme neprepustili do stránky potencionálneho útočnika.
					Ktorý by mohol ukradnúť používateľské (vaše) dáta a predávať ich na čiernom trhu.
				</p>
				<p className='anotherthats'>
					Verím že vás táto stránka niečo priučila a napomohla k bezpečnejšej púti internetom.
					Nezabúdajte, vytvárajte si silné heslo, Kontrolujte či je stránka https a v neposlednom rede
					kontrolujte či má stránka  <b onClick={() => navig('/')}>WAF</b>
				</p>
			</div>
    	</div>
  	);
}

export default About;
